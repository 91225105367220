import React from 'react'
import PDFViewer from 'pdf-viewer-reactjs-bulma-wrapped';
import file from '../exposes/nr1.pdf'
import 'pdf-viewer-reactjs-bulma-wrapped/css/main.css';
 
const Expose1 = () => {
    return (
        <PDFViewer
              
            document={{
                url: file,
            }}
        />
    )
}
 
export default Expose1
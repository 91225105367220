import React from "react"
import Layout from "../components/layout"
import Exp1 from '../components/expose1'

const Blog = () => {

  return (
    <Layout>
      <h1>Immobilien zum Verkauf</h1>
      <div className="container my-12 mx-auto px-4 md:px-12">
        <Exp1></Exp1>
      </div>


    </Layout >
  )
}

export default Blog